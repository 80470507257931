import axios from "axios";
import values from "../settings";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = (values.env == "PROD") ? values.prod_serverUrl : values.dev_serverUrl;

// Account
async function syncAccount() {
    const response = await axios
        .post(
            '/dashboard/account/sync',
            {},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Account Metrics
async function getBusinessMetrics() {
    const response = await axios
        .post(
            '/dashboard/account/metrics/get',
            {},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getFinantialStateData(timeFilter) {
    const response = await axios
        .post(
            '/dashboard/account/metrics/finantial-data/get',
            {filter: timeFilter},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getRecentSales() {
    const response = await axios
        .post(
            '/dashboard/account/metrics/recent-sales/get',
            {},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Authentication
async function login(email, password) {
    const data = {
        email: email,
        password: password
    };
    
    const response = await axios
        .post('/dashboard/auth/sign-in', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}
async function logout() {
    const data = {};
    const response = await axios
        .post('/dashboard/auth/sign-out', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}
async function updatePassword(oldPass, newPass) {
    const data = {old: oldPass, new: newPass};
    const response = await axios
        .post('/dashboard/auth/password/update', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Business
async function getBusiness() {
    const response = await axios
        .post('/dashboard/business/get')
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveBusiness(form) {
    const response = await axios
        .post(
            '/dashboard/business/save',
            form,   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getEconActivities() {
    const response = await axios
        .post('/dashboard/business/activity/get-all')
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveEconActivity(form) {
    const response = await axios
        .post(
            '/dashboard/business/activity/save',
            form,   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function setDefaultEconActivity(id) {
    const response = await axios
        .post(
            '/dashboard/business/activity/default/save',
            {id: id},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveDefaults(defaults) {
    const data = {defaults: defaults};
    const response = await axios
        .post('/dashboard/business/defaults/save', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Services
async function getServicePlans() {
    const response = await axios
        .post(
            '/dashboard/services/get-all',
            {},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Subscription
async function getSubscriptions() {
    const response = await axios
        .post(
            '/dashboard/subscription/get-all',
            {},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}
async function renewSubscription(form) {
    const response = await axios
        .post(
            '/dashboard/subscription/renew',
            form,   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Device
async function getDevices() {
    const response = await axios
        .post(
            '/dashboard/device/get-all',
            {},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function selectDevice(id) {
    const response = await axios
        .post(
            '/dashboard/device/select',
            {deviceId: id},   
        )
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Message & Broadcast
async function getBroadcastMessage() {
    const data = {};
    const response = await axios
        .post('/dashboard/broadcast/get', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function seenBroadcastMessage(messageId) {
    const data = {messageId: messageId};
    const response = await axios
        .post('/dashboard/broadcast/seen', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Customer
async function getCustomers(filter=null) {
    var data = {};
    if (filter !== null) { data["filter"] = filter }

    const response = await axios
        .post('/dashboard/customer/get-all', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveCustomer(customer) {
    const response = await axios
        .post('/dashboard/customer/save', JSON.stringify(customer))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteCustomers(ids) {
    const data = {ids: ids};
    const response = await axios
        .post('/dashboard/customer/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadClients() {
    const data = {};
    const response = await axios
        .post('/dashboard/customer/download', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })

    return response
}

// Product
async function getProducts(filter=null, filterType=null) {
    var data = {};
    if (filter !== null) { data["filter"] = filter }
    else if (filterType !== null) { data["filterType"] = filterType }
    const response = await axios
        .post('/dashboard/product/get-all', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveProduct(product) {
    const response = await axios
        .post('/dashboard/product/save', JSON.stringify(product))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteProducts(ids) {
    const data = {ids: ids};
    const response = await axios
        .post('/dashboard/product/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadProducts() {
    const data = {};
    const response = await axios
        .post('/dashboard/product/download', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })

    return response
}

async function getProductSalesReport(initialDate, finalDate) {
    const data = {initialDate: initialDate, finalDate: finalDate};
    const response = await axios
        .post('/dashboard/product/report/sale', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })

    return response
}

// Provider
async function getProviders(lite=false, filter=null) {
    var data = {lite: lite};
    if (filter !== null) { data['filter'] = filter }
    const response = await axios
        .post('/dashboard/product/provider/get-all', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveProvider(provider) {
    const response = await axios
        .post('/dashboard/product/provider/save', JSON.stringify(provider))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteProviders(ids) {
    const data = {ids: ids};
    const response = await axios
        .post('/dashboard/product/provider/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Balance
async function getBalance(initialDate, finalDate, searchType) {
    const data = {
        initialDate: initialDate,
        finalDate: finalDate,
        searchType: searchType
    }
    
    const response = await axios
        .post('/dashboard/balance/get', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function printBalance(data) {
    const response = await axios
        .post('/dashboard/balance/print', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Proforma
async function getProformas(client=null) {
    var data = {};
    if (client!==null && client !== "") { data["client"] = client }

    const response = await axios
        .post('/dashboard/proforma/get-all', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getProforma(proformaId) {
    const data = {proformaId: proformaId};
    const response = await axios
        .post('/dashboard/proforma/get', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveProforma(proforma) {
    var data = {...proforma};
    data = JSON.stringify(data);
    
    const response = await axios
        .post('/dashboard/proforma/save', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function sendProformaEmail(proformaId, emails) {
    const data = {proformaId: proformaId, emails: emails};
    const response  = await axios
        .post('/dashboard/proforma/send', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error =>{ 
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadProformaFile(proformaId) {
    const data = {proformaId: proformaId};
    const response  = await axios
        .post('/dashboard/proforma/file/download', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error =>{ 
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteProforma(id) {
    const data = {proformaId: id};
    const response = await axios
        .post('/dashboard/proforma/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Sale
async function getSales(client=null, status=null, start=null, end=null, amount=null, collected=null, receipt=null) {
    var data = {};
    if (client!==null && client !== "") { data["client"] = client }
    if (status!==null) { data["status"] = status }
    if (start!==null) { data["start"] = start }
    if (end!==null) { data["end"] = end }
    if (amount!==null && amount!== "") { data["amount"] = amount }
    if (collected!==null) { data["collected"] = collected }
    if (receipt!==null) { data["receipt"] = receipt }

    const response = await axios
        .post('/dashboard/sale/get-all', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getSale(saleId) {
    const data = {saleId: saleId};
    const response = await axios
        .post('/dashboard/sale/get', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveSale(sale, receipt) {
    var data = {...sale};
    if (receipt === true) { data["receipt"] = "True" }
    data = JSON.stringify(data);
    
    const response = await axios
        .post('/dashboard/sale/save', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function collectSale(saleId) {
    const data = JSON.stringify({id: saleId});
    const response = await axios
        .post('/dashboard/sale/collect', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function printSale(id) {
    const data = {saleId: id};
    const response = await axios
        .post('/dashboard/sale/print', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteSale(id) {
    const data = {saleId: id};
    const response = await axios
        .post('/dashboard/sale/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadSales(format, saleType, initialDate, endDate) {
    const data = {
        format: format,
        saleType: saleType,
        initialDate: initialDate,
        endDate: endDate
    };
    const response = await axios
        .post('/dashboard/sale/download-report', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })

    return response
}

async function downloadSaleEdoc(edocId, type) {
    const data = {edocId: edocId, type: type};
    const response  = await axios
        .post('/dashboard/sale/download-edoc', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error =>{ 
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Purchase
async function getPurchases(title=null, start=null, end=null, paid=null, edoc=null, amount=null) {
    const data = {};
    if (title !== null && title !== "") { data.title = title }
    if (start !== null) { data.start = start }
    if (end !== null) { data.end = end }
    if (paid !== null) { data.paid = paid }
    if (edoc !== null) { data.edoc = edoc }
    if (amount !== null) { data.amount = amount }

    const response = await axios
        .post('/dashboard/purchase/get-all', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getPurchase(purchaseId) {
    const data = {purchaseId: purchaseId};
    const response = await axios
        .post('/dashboard/purchase/get', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function savePurchase(purchase) {
    var data = {...purchase};
    data = JSON.stringify(data);
    const response = await axios
        .post('/dashboard/purchase/save', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function attemptToCreateRetention(purchaseId) {
    let data = {purchaseId: purchaseId};
    data = JSON.stringify(data);
    const response = await axios
        .post('dashboard/purchase/edoc/create', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function payPurchase(purchaseId) {
    const data = {purchaseId: purchaseId};
    const response = await axios
        .post('/dashboard/purchase/pay', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deletePurchase(purchaseId) {
    const data = {purchaseId: purchaseId};
    const response = await axios
        .post('/dashboard/purchase/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadPurchase(purchaseId, type) {
    const data = {purchaseId: purchaseId, type: type};
    const response  = await axios
        .post('/dashboard/purchase/download-edoc', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error =>{ 
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadPurchaseReport(format, initialDate, endDate) {
    const data = {
        format: format,
        initialDate: initialDate,
        endDate: endDate
    };
    
    const response = await axios
        .post('/dashboard/purchase/download-report', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })

    return response
}

// Remision Guide
async function getGuides() {
    const response = await axios
        .post('/dashboard/guide/get-all')
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getGuide(guideId) {
    const data = {guideId: guideId};
    const response = await axios
        .post('/dashboard/guide/get', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function searchGuideDestination(searchText) {
    const data = {searchText: searchText};
    const response = await axios
        .post('/dashboard/guide/destinations/search', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function searchGuideCourrier(searchText) {
    const data = {searchText: searchText};
    const response = await axios
        .post('/dashboard/guide/courrier/search', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveGuide(guide) {
    var data = {...guide};
    data = JSON.stringify(data);
    const response = await axios
        .post('/dashboard/guide/save', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function attemptToCreateRemisionGuide(guideId, saleId) {
    var data = {guideId: guideId, saleId: saleId};
    data = JSON.stringify(data);
    const response = await axios
        .post('dashboard/guide/edoc/create', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteGuide(guideId) {
    const data = {guideId: guideId};
    const response = await axios
        .post('/dashboard/guide/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadGuide(guideId, type) {
    const data = {guideId: guideId, type: type};
    const response  = await axios
        .post('/dashboard/guide/download', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error =>{ 
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

// Credit Notes
async function getCreditNotes(customer=null) {
    const data = {customer: customer};
    const response = await axios
        .post('/dashboard/credit-note/get-all', JSON.stringify(data),)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function getCreditNote(id) {
    const data = {id: id};
    const response = await axios
        .post('/dashboard/credit-note/get', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function saveCreditNote(creditNote) {
    var data = {...creditNote};
    const response = await axios
        .post('/dashboard/credit-note/save', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}


async function attemptToCreateCreditNote(creditNoteId) {
    var data = {id: creditNoteId};
    data = JSON.stringify(data);
    const response = await axios
        .post('dashboard/credit-note/edoc/create', data)
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function deleteCreditNote(creditNoteId) {
    const data = {creditNoteId: creditNoteId};
    const response = await axios
        .post('/dashboard/credit-note/delete', JSON.stringify(data))
        .then(response => { return response.data; })
        .catch(error => {
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

async function downloadCreditNote(creditNoteId, type) {
    const data = {creditNoteId: creditNoteId, type: type};
    const response  = await axios
        .post('/dashboard/credit-note/download', JSON.stringify(data), {responseType: 'blob'})
        .then(response => { return response.data; })
        .catch(error =>{ 
            console.error("There was an error!", error);
            return {status: 404}
        })
    return response
}

export default {
    // Account
    syncAccount,
    getBusinessMetrics,
    getFinantialStateData,
    getRecentSales,
    // Authentication
    login,
    logout,
    updatePassword,
    // Business
    getBusiness,
    saveBusiness,
    getEconActivities,
    saveEconActivity,
    setDefaultEconActivity,
    saveDefaults,
    // Service Plans
    getServicePlans,
    // Subscriptions
    getSubscriptions,
    renewSubscription,
    // Device,
    getDevices,
    selectDevice,
    // Message & Broadcast
    getBroadcastMessage,
    seenBroadcastMessage,
    // Customer
    getCustomers,
    saveCustomer,
    deleteCustomers,
    downloadClients,
    // Product
    getProducts,
    saveProduct,
    deleteProducts,
    downloadProducts,
    getProductSalesReport,
    // Providers
    getProviders,
    saveProvider,
    deleteProviders,
    // Balance
    getBalance,
    printBalance,
    // Proformas
    getProformas,
    getProforma,
    saveProforma,
    sendProformaEmail,
    downloadProformaFile,
    deleteProforma,
    // Sales
    getSales,
    getSale,
    saveSale,
    collectSale,
    printSale,
    deleteSale,
    downloadSales,
    downloadSaleEdoc,
    // Purchases
    getPurchases,
    getPurchase,
    savePurchase,
    attemptToCreateRetention,
    payPurchase,
    deletePurchase,
    downloadPurchase,
    downloadPurchaseReport,
    // Remision Guide
    getGuides,
    getGuide,
    searchGuideDestination,
    searchGuideCourrier,
    saveGuide,
    attemptToCreateRemisionGuide,
    deleteGuide,
    downloadGuide,
    // Credit Note
    getCreditNotes,
    getCreditNote,
    saveCreditNote,
    attemptToCreateCreditNote,
    deleteCreditNote,
    downloadCreditNote,
}